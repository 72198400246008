.about {
  flex-direction: column;
  margin-top: 3em;
}

.about__name, .university {
  color: var(--clr-primary);
  background-color: transparent;
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}

.propic-cont {
  position: relative;
  margin-bottom: 40px;
  width: 256px;
  height: 256px;
}

.propic {
  left: 0px;
  position: absolute;
  width: 256px;
  border-radius: 50%;

  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

.propic-top {
  box-shadow: var(--shadow);
}

/* .propic-bottom {
  box-shadow: var(--shadow);
} */

.light .propic-top {
  --shadow: rgba(100, 100, 111, 0.4) 0px 15px 29px 0px;
}

.propic-bottom {
  opacity: 0;
}

/* .propic-top:hover {
  opacity: 0;
} */

/* .propic:hover {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
} */
