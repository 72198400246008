.publications__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2em;
}

.publications__grid h4 {
  text-align: left;
  /* margin-left: 1em; */
}

.publications__grid p {
  text-align: left;
  margin-bottom: 1em;
  /* margin-left: 1em; */
}
